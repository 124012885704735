<template>
  <div class="page">
    <!-- banner图 -->
    <BannerHead
      :bannerImg="bannerImg"
      :bigTitle_1="bigTitle_1"
      :smallTitle_1="smallTitle_1"
    ></BannerHead>

    <!-- 核心功能 -->
    <CoreFunction :spanList="spanList" :coreImg="coreImg"></CoreFunction>

    <!-- 差异优势 -->
    <Different :diffImg="diffImg" :diffList="diffList"></Different>

    <!-- 应用场景 -->
    <Application :appImg="appImg" :appliList="appliList"></Application>

     <!-- 尾部 -->
    <Footering></Footering>
  </div>
</template>

<script>
// banner
import BannerHead from "@/components/centerPage/banner_head.vue";
import CoreFunction from "@/components/centerPage/core_function.vue";
import Different from "@/components/centerPage/differential_adv.vue";
import Application from "@/components/centerPage/application.vue";

import banner1 from "@/assets/img/children/banner_1.png";
import hexin from "@/assets/img/children/hexin.png";
import chayi from "@/assets/img/children/chayi.png";
import yingyong from "@/assets/img/children/yingyong.png";

// foote组件
import Footering from "../../components/foot/viewfooter.vue";

// 公用组件
import "../../assets/css/public.css";

export default {
  components: {
    BannerHead,
    CoreFunction,
    Different,
    Application,
    Footering
  },
  data() {
    return {
      bannerImg: banner1,
      coreImg: hexin,
      bigTitle_1: "色情商品识别",
      smallTitle_1:
        '识别商品图片中品牌logo，支持跨境电商常见的180种品牌',

      // 核心功能
      spanList: [
        {
          firstSpan: "商品logo识别",
          secondSpan:
            "识别180种境外商品logo，可准确识别图片中品牌logo的名称和所在图片中的位置",
        },
      ],

      //   差异优势
      diffImg: chayi,
      diffList: [
        {
          diffFirst: "覆盖度",
          diffSecond:
            "180个品牌基本涵盖境外常用品牌，且覆盖全品类，基本满足业务场景需求",
        },
      ],
      
      //   应用场景
      appImg: yingyong,
      appliList: [
        {
          appliFirst: "电商平台/店铺安全",
          appliSecond:
            "识别商品品牌，实现平台商品准入及风险管控。",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
